<template>
  <div class="">
    <div class="container container--header">
      <div class="header">
        <a href="/" class="header__logo header__logo--log"/>
      </div>
    </div>
    <div class="container">
      <div class="login-block">
        <div class="login-block__wrapper">
          <h1 class="login__tit">
            Раскрой женственность
          </h1>
          <p class="login__txt">
            Урок от психолога-сексолога
          </p>
          <form class="login-form">
            <div class="login-form__blocks">
              <div class="login-form__block">
                <span class="login-form__block-icon" />
                <input
                  v-model="user.email"
                  type="email"
                  class="login-form__input"
                  placeholder="Укажите ваш email"
                >
              </div>
              <div class="login-form__block">
                <span class="login-form__block-icon login-form__block-icon--2"/>
                <input
                  v-model="user.password"
                  :type="showPassword ? 'text' : 'password'"
                  class="login-form__input"
                  placeholder="Пароль"
                >
                <button
                  class="login-form__hide"
                  @click.prevent="showPassword = !showPassword"
                />
              </div>
            </div>
            <div class="button__block">
              <button class="login-form__button" @click.prevent="login" :disabled="loading">
                {{ loading ? 'Загрузка...' : 'Войти' }}
              </button>
              <span class="error" v-if="error">{{ error }}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPassword: false,
      user: {
        email: null,
        password: null,
        guide: 16,
      },
      error: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
      this.user.password = this.$route.query.password;
      this.login();
    }
    document.documentElement.classList.add('bg--log')
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = '';
      const response = await this.$request.post('/api/guide/login', this.user);
      if (response.status !== 200 || !response.data.success) {
        this.error = 'Неправильный логин или пароль';
      } else {
        localStorage.setItem('token', response.data.result.token);
        this.$router.replace('/');
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.error {
  color: #f00;
  display: block;
  font-size: 1.6rem;
}
</style>
